import { isEmpty } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { notifier, TagOption, tagUtils } from 'utils';

import { tagsApi } from 'api/tag';
import { Tag } from 'types/Tags/tags';

import './styles.less';

type Props = {
  readonly selectedTags: string[];
  readonly onChange: (tags: string[]) => void;
};

export const TagSelector: React.FC<Props> = memo(
  ({ selectedTags, onChange }) => {
    const [existingTags, setExistingTags] = useState<Tag[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      const getTags = async () => {
        try {
          setLoading(true);
          const response = await tagsApi.getAll();
          const filterResponse = response.tags.filter(tag =>
            tagUtils.nonDepartmentTag(tag)
          );
          setExistingTags(filterResponse);
          setLoading(false);
        } catch (err) {
          notifier.requestFailed(err);
        }
      };
      getTags();
    }, []);

    const availableOptions: TagOption[] = existingTags
      ? existingTags.map(option => {
          return {
            key: option.id,
            value: `${option.name}:${option.value}`,
            text: `${option.name}:${option.value}`
          };
        })
      : [];

    const placeholderText = isEmpty(availableOptions)
      ? 'No tags available'
      : 'Select Tags';
    return (
      <div className='cl-tag-selection'>
        <h4 className='section-number'>
          {
            <span>
              4. Select a Tag <i>(optional)</i>
            </span>
          }
        </h4>
        <Dropdown
          className='tag'
          placeholder={placeholderText}
          selection
          multiple
          search
          loading={loading}
          value={selectedTags}
          options={availableOptions}
          onChange={(event, data) => {
            onChange(data.value as string[]);
          }}
        />
      </div>
    );
  }
);
