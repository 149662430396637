import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  DropdownProps,
  Icon,
  Message,
  Modal
} from 'semantic-ui-react';
import { contactUtils, notifier } from 'utils';

import { tagsApi } from 'api/tag';
import { useContactMutations } from 'hooks/useContacts';
import { Contact } from 'types';

import { setDepartments } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';
import { Nbsp } from '../Nbsp/Nbsp';

export function ChooseDepartmentModal(props: {
  contacts: Contact[];
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const multiContactsSelected = props.contacts && props.contacts.length > 1;
  const [department, setDepartment] = useState<string>('');
  const getLocalTag = () => {
    if (multiContactsSelected) {
      setDepartment(availableOptions[0].value);
    } else if (props.contacts && props.contacts.length === 1) {
      if (contactUtils.formatField(props.contacts[0].department) === '-') {
        setDepartment(availableOptions[0].value);
      } else {
        setDepartment(props.contacts[0].department);
      }
    }
  };
  const allDepartments = useSelector((state: RootState) => state.department);
  const dispatch = useDispatch();

  const availableOptions = allDepartments.map((option: string) => {
    return {
      key: option,
      value: option,
      text: option
    };
  });
  const { updateContact } = useContactMutations();

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const res = await tagsApi.getAllDepartments();
        setDepartment(
          props.contacts && props.contacts.length === 1
            ? props.contacts[0].department
            : res.departments && res.departments[0]
        );
        dispatch(setDepartments(res.departments));
      } catch (err) {
        notifier.requestFailed(err);
      }
    };
    getDepartments();
  }, [dispatch, props.contacts]);

  const updateDepartment = async () => {
    if (props.contacts === undefined) {
      return;
    }
    const successful = [];
    const failed = [];
    for (const contact of props.contacts) {
      try {
        await new Promise((resolve, reject) => {
          updateContact.mutate(
            {
              contact,
              contactRequest: { department }
            },
            {
              onSuccess: data => resolve(data),
              onError: error => reject(error)
            }
          );
        });
        successful.push(contact);
      } catch (error) {
        failed.push({ contact, error });
      }
    }
    if (failed.length === 0) {
      if (successful.length <= 5) {
        notifier.success({
          message: `Contact${successful.length > 1 ? 's' : ''} ${successful
            .map(c => c.name)
            .join(', ')} ${
            successful.length > 1 ? 'were' : 'was'
          } successfully updated`
        });
      } else {
        notifier.success({
          message: `${successful.length} contacts were successfully updated`
        });
      }
    } else if (successful.length === 0) {
      notifier.error({
        message: `Failed to update contacts`
      });
      failed.forEach(fail => {
        notifier.error({
          message: fail.error
        });
      });
    } else {
      notifier.error({
        message: `Updated ${successful.length} contacts, but failed to update ${failed.length} contacts`
      });
      failed.forEach(fail => {
        notifier.error({
          message: fail.error
        });
      });
    }
    props.setOpen(false);
  };

  const contactName: string =
    props.contacts && props.contacts.length === 1 ? props.contacts[0].name : '';

  return (
    <Modal
      open={props.open}
      closeIcon={true}
      closeOnDimmerClick={false}
      size='tiny'
      onClose={() => {
        getLocalTag();
        props.setOpen(false);
      }}
    >
      <Modal.Header>Manage Department for {contactName}</Modal.Header>
      <Modal.Content>
        <span>
          Please select a value to Add or Edit the user's Department. <Nbsp />
        </span>
        <div className='modal-content'>
          <Modal.Description>
            <div>
              <Dropdown
                placeholder='Select Department'
                fluid
                selection
                text={department}
                value={department}
                options={availableOptions}
                onChange={(event, data: DropdownProps) => {
                  setDepartment(data.value as string);
                }}
              />
            </div>
          </Modal.Description>
        </div>
        {multiContactsSelected && (
          <Message
            warning
            content='Note: Multiple Contacts selected. Please choose departments carefully.'
          />
        )}
        <Message
          className='modal-content'
          warning
          content='Note: If a Department has already been assigned to the user, submitting the form will override the Department.'
        />
      </Modal.Content>
      <Modal.Actions>
        <div className='flex-1'>
          <Button className='' color='red' onClick={() => props.setOpen(false)}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button color='green' onClick={() => updateDepartment()}>
            <Icon name='checkmark' /> Submit
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}
