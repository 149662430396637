import { Tag } from 'types/Tags/tags';

const nonDepartmentTag = (tag: Tag) => {
  return tag.name !== 'department';
};

export const tagUtils = {
  nonDepartmentTag
};

export type TagOption = {
  key: number;
  value: string;
  text: string;
};
