import React, { memo, useEffect, useState } from 'react';
import { notifier } from 'utils';

import { PartnerContactDetailsModal } from 'components/PartnerContactDetailsModal';
import { useContactMutations } from 'hooks/useContacts';
import { Contact } from 'types';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly contact: Contact;
};

export const ContactDetailsModal: React.FC<Props> = memo(
  ({ open, onClose, contact }) => {
    contact = contact || {};

    const [localContact, setLocalContact] = useState<Contact>(contact);
    const { updateContact } = useContactMutations();

    useEffect(() => {
      if (open) {
        setLocalContact(contact);
      }
    }, [open, contact]);

    const onSubmit = async () => {
      try {
        await updateContact.mutate({
          contact,
          contactRequest: localContact
        });
        notifier.success({
          message: `Contact "${contact.name}" successfully updated`
        });
      } catch (err) {
        notifier.requestFailed(err);
      }
      onClose();
    };

    return (
      <PartnerContactDetailsModal
        open={open}
        contact={localContact}
        onChange={(value: Contact) => setLocalContact(value)}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    );
  }
);
