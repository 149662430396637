import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { Contact } from 'types';

export function undefinedIfNull(s: string | null): string | undefined {
  if (s === null || s === '') {
    return undefined;
  } else {
    return s;
  }
}

const ROLE_MAPPINGS = new Map([
  ['customer', ['external']],
  ['partner', ['partner', 'partner_admin']]
]);

export function apiRoleFromUIRole(uiRoles?: string[]): string[] | undefined {
  if (isEmpty(uiRoles) || uiRoles?.includes('all')) {
    return undefined;
  }
  return uiRoles?.flatMap(role => ROLE_MAPPINGS.get(role) || [role]);
}

export interface ContactsState {
  selected: Contact[];
}
const initialState: ContactsState = {
  selected: []
};
export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setSelectedContacts: (
      state: ContactsState,
      action: PayloadAction<Contact[]>
    ) => {
      state.selected = action.payload;
    }
  }
});

export const { setSelectedContacts } = contactsSlice.actions;

export default contactsSlice.reducer;
