import { URLParamConfig } from 'utils/urlStateParser';

import { DEFAULT_PAGE_SIZE } from './pagination';

export const CONTACTS_URL_PARAMS: URLParamConfig[] = [
  {
    name: 'filterText',
    type: 'string',
    defaultValue: '',
    urlParam: 'filter'
  },
  {
    name: 'roleText',
    type: 'array',
    defaultValue: [],
    urlParam: 'role'
  },
  {
    name: 'limit',
    type: 'number',
    defaultValue: DEFAULT_PAGE_SIZE
  },
  {
    name: 'offset',
    type: 'number',
    defaultValue: 0
  }
];

export const TOPOLOGY_URL_PARAMS: URLParamConfig[] = [
  {
    name: 'filterText',
    type: 'string',
    defaultValue: '',
    urlParam: 'filter'
  },
  {
    name: 'selectedTags',
    type: 'array',
    defaultValue: [],
    urlParam: 'tags'
  },
  {
    name: 'limit',
    type: 'number',
    defaultValue: DEFAULT_PAGE_SIZE
  },
  {
    name: 'offset',
    type: 'number',
    defaultValue: 0
  }
];

export const LAB_URL_PARAMS: URLParamConfig[] = [
  {
    name: 'filterText',
    type: 'string',
    defaultValue: '',
    urlParam: 'filter'
  },
  {
    name: 'limit',
    type: 'number',
    defaultValue: DEFAULT_PAGE_SIZE
  },
  {
    name: 'offset',
    type: 'number',
    defaultValue: 0
  }
];
