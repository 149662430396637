import React, { memo, useEffect } from 'react';
import { Divider } from 'semantic-ui-react';
import { urlStateParser } from 'utils/urlStateParser';

import { LabActionContainer, Labs, LabSearch } from 'components/Labs';
import { LAB_URL_PARAMS } from 'constants/url-params';
import { useRouter } from 'hooks/useRouter';
import { MainLayout } from 'layouts';

export const LabsView: React.FC = memo(() => {
  const router = useRouter();
  const getInitialStateFromURL = (location: { search: string }) => {
    return urlStateParser.getStateFromURL(location, LAB_URL_PARAMS);
  };
  const { filterText, limit, offset } = getInitialStateFromURL(router.location);
  const [filterInput, setFilterInput] = React.useState<string>(
    filterText || ''
  );
  useEffect(() => {
    const newParams = urlStateParser.stateToURLParams(
      { filterText: filterInput, limit, offset },
      LAB_URL_PARAMS
    );
    const currentParams = new URLSearchParams(router.location.search);

    if (newParams.toString() !== currentParams.toString()) {
      router.history.push({
        pathname: router.pathname,
        search: newParams.toString()
      });
    }
  }, [
    filterInput,
    limit,
    offset,
    router.location.search,
    router.history,
    router.pathname
  ]);
  return (
    <MainLayout className='cl-topologies-view'>
      <div className='caption'>
        <h2 className='header'>Labs</h2>
        <div className='actions'>
          <LabSearch filter={filterInput} setFilter={setFilterInput} />
          <div className='flex-1' />
          <LabActionContainer />
        </div>
      </div>
      <Divider />
      <Labs
        filter={filterInput}
        displayedColumns={[
          'lab name',
          'region',
          'creation time',
          'expiration time',
          'created by',
          'created for',
          'status',
          'progress',
          'topology',
          'actions'
        ]}
        selectionType={'multi'}
        limit={limit}
        offset={offset}
      />
    </MainLayout>
  );
});
