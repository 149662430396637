import axios from 'axios';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { notifier } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { ContactListResponse } from 'interfaces';
import { Contact } from 'types';

import qs from 'qs';

export const useGetAllContacts = (params: {
  filter?: string | undefined;
  offset?: number;
  limit?: number;
  role?: string[] | undefined;
}): UseQueryResult<ContactListResponse> => {
  return useQuery<ContactListResponse>('Contacts', () => {
    return axios
      .get(apiPrefix(apiRoutes.contacts), {
        params,
        paramsSerializer: param => {
          return qs.stringify(param, { arrayFormat: 'repeat' });
        }
      })
      .then(response => (response.data ? response.data : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};

export const useGetContact = (id: number): UseQueryResult<Contact> =>
  useQuery<Contact>('Contact', () => {
    return axios
      .get(apiPrefix(`${apiRoutes.contacts}/${id}`))
      .then(response => response.data)
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });

export const useContactMutations = () => {
  const queryClient = useQueryClient();

  const createContact = useMutation(
    (contactRequest: Partial<Contact>) => {
      return axios
        .post(apiPrefix(apiRoutes.contacts), contactRequest)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSettled: () => queryClient.invalidateQueries('Contacts') }
  );

  const updateContact = useMutation(
    (data: { contact: Contact; contactRequest: Partial<Contact> }) => {
      return axios.patch(
        apiPrefix(`${apiRoutes.contacts}/${data.contact.id}`),
        data.contactRequest
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('Contacts');
        queryClient.invalidateQueries('Contact');
      }
    }
  );

  const deleteContact = useMutation(
    (contact: Contact) => {
      return axios
        .delete(apiPrefix(`${apiRoutes.contacts}/${contact.id}`))
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('Contacts');
        queryClient.invalidateQueries('Contact');
      }
    }
  );

  return {
    createContact,
    updateContact,
    deleteContact
  };
};
