import React, { memo, useState } from 'react';
import { Icon, Loader, Menu, MenuItem } from 'semantic-ui-react';
import { notifier, onSendEmail, topologyName, TopologyStatus } from 'utils';

import { topologiesApi } from 'api/topology';
import { ExtendTopologyDuration } from 'components';
import { Nbsp } from 'components/Nbsp/Nbsp';
import { TopologyDetails } from 'types';
import { TagSelectionModal } from 'views/TagSelectionModal';
import { ConfirmTopologyDelete } from 'views/Topologies/ConfirmTopologyDelete';

import { CopyTopologyDetailsMenuItem } from './CopyTopologyDetailsMenuItem';
import { LogsMenuItem } from './LogsMenuItem';

import './styles.less';

type Props = {
  readonly topology: TopologyDetails;
  readonly refetchData: () => void;
};

const DeleteTopologyMenuItem: React.FC<Props> = ({ topology, refetchData }) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  return (
    <>
      <MenuItem onClick={() => setConfirmDeleteModalOpen(true)} role='button'>
        <Icon name='trash' />
        Delete
      </MenuItem>
      <ConfirmTopologyDelete
        selectedTopologies={[topology]}
        setOpen={setConfirmDeleteModalOpen}
        open={confirmDeleteModalOpen}
        onDelete={() => {
          setConfirmDeleteModalOpen(false);
          topologiesApi
            .remove(topology.uuid)
            .then(() => {
              notifier.success({
                message: `Topology ${topologyName(
                  topology
                )} scheduled for deletion`
              });
              refetchData();
            })
            .catch(notifier.requestFailed);
        }}
      />
    </>
  );
};

export const TopologyMenu: React.FC<Props> = memo(
  ({ topology, refetchData }) => {
    const [confirmTagModelOpen, setConfirmTagModelOpen] = useState(false);
    return (
      <>
        <Menu fluid stackable className='stackable-topology-menu'>
          <DeleteTopologyMenuItem
            topology={topology}
            refetchData={refetchData}
          />
          <ExtendTopologyDuration
            topology={topology}
            onSuccess={refetchData}
            trigger={
              <span>
                <Icon name='stopwatch' />
                Extend duration
              </span>
            }
            loadingTrigger={
              <Menu.Item name='extend-duration' disabled={true}>
                <div>
                  <Icon name='stopwatch' />
                  Extend duration
                </div>{' '}
                <Nbsp />
                <Loader inline active size='tiny' />
              </Menu.Item>
            }
            enabled={topology.state === TopologyStatus.UP}
            item={true}
            showHeader={false}
          />
          <LogsMenuItem topology={topology} />
          <MenuItem onClick={() => onSendEmail(topology)} role='button'>
            <span>
              <Icon name='envelope' />
              Send Topology Details to Owner
            </span>
          </MenuItem>
          <CopyTopologyDetailsMenuItem topology={topology} />
        </Menu>
        <TagSelectionModal
          open={confirmTagModelOpen}
          onClose={setConfirmTagModelOpen}
          topologies={[topology]}
        />
      </>
    );
  }
);
