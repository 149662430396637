import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { Loader } from 'semantic-ui-react';

import { LabSidebar } from 'components/LabSidebar';
import { routes } from 'constants/routing';
import { useGetLab, useLabSidebarState } from 'hooks/useLabTemplate';
import { useRouter } from 'hooks/useRouter';
import { MainLayout } from 'layouts';
import { Lab, LabContext } from 'types';

import { LabDetailView } from './LabDetailView';

const REFETCH_INTERVAL = 5 * 60 * 1000;

export const LabViewContext = createContext<LabContext | null>(null);

export const LabView: React.FC = memo(() => {
  const router = useRouter();
  const uuid = (router.match.params as { uuid: string }).uuid;

  const { data: lab, isLoading, refetch, isRefetching } = useGetLab(uuid);
  const [sidebarState, updateSidebarState] = useLabSidebarState(
    lab ? lab : ({} as Lab)
  );

  const areAllTopologiesUp = useCallback((l?: Lab | null) => {
    if (!l?.topologies?.length) return false;
    return l.topologies.every(topology => topology.state === 'up');
  }, []);

  useEffect(() => {
    let refetchInterval: NodeJS.Timeout;

    if (lab && !areAllTopologiesUp(lab) && !isRefetching) {
      refetchInterval = setInterval(async () => {
        const result = await refetch();
        if (areAllTopologiesUp(result.data)) {
          clearInterval(refetchInterval);
        }
      }, REFETCH_INTERVAL);
    }

    return () => {
      if (refetchInterval) {
        clearInterval(refetchInterval);
      }
    };
  }, [lab, refetch, areAllTopologiesUp, isRefetching]);

  const breadcrumbSections = useMemo(
    () => [
      {
        content: routes.labs.label,
        href: routes.labs.path
      },
      {
        content: isLoading ? (
          <Loader active inline='centered' size='tiny' />
        ) : (
          lab?.label || null
        ),
        active: true
      }
    ],
    [isLoading, lab?.label]
  );

  const contextValue = useMemo(
    () => ({
      lab: lab || null,
      isLoading: isLoading || isRefetching,
      sidebarState,
      updateSidebarState
    }),
    [lab, isLoading, isRefetching, sidebarState, updateSidebarState]
  );

  return (
    <LabViewContext.Provider value={contextValue}>
      <MainLayout
        breadcrumbSections={breadcrumbSections}
        visible={sidebarState.isVisible}
        onVisibleChange={visible => updateSidebarState({ isVisible: visible })}
        sidebarChilden={<LabSidebar />}
      >
        <LabDetailView />
      </MainLayout>
    </LabViewContext.Provider>
  );
});
