import { isEmpty } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { host, interpolateRoute } from 'utils';
import { getLabTemplateUrls } from 'utils/labs';
import { isNullOrUndefined } from 'utils/utilFunctions';

import { routes } from 'constants/routing';
import { useLabMutations } from 'hooks/useLabTemplate';
import { Lab } from 'types';

import { setCurrentLab, setSidebarIsContentType } from 'features/labs';
import { RootState } from 'Reducers/contactReducer';
import { LabGuidePageNavigator } from '../LabGuidePageNavigator';
import { LabCompletionActions } from './LabCompletionActions';
import { LabDetailsOverlay } from './LabDetailsOverlay/LabDetailsOverlay';

import './styles.less';

type Props = {
  readonly lab: Lab;
  readonly visible: boolean;
  readonly onVisibleChange: (visible: boolean) => void;
  readonly onContentChange: (content: boolean) => void;
};

const isFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input != null && input.tagName === 'IFRAME';

export const setIFrameAnchorsTarget = (document: Document) => {
  const anchors = document.querySelectorAll('a');
  anchors.forEach(anchor => {
    if (anchor.hostname !== host) {
      anchor.setAttribute('target', '_blank');
    }
  });
};

const NavigationButtons: React.FC<{
  visible: boolean;
  topologyUP: boolean;
  onLabDetailsClick: () => void;
  onContentsClick: () => void;
}> = memo(({ visible, topologyUP, onLabDetailsClick, onContentsClick }) => {
  if (visible) return null;

  return (
    <div className='sticky-div'>
      <Button
        className='align-right'
        data-testid='lab-details-button'
        disabled={!topologyUP}
        onClick={onLabDetailsClick}
      >
        <Icon name='info circle' />
        Lab Details
      </Button>
      <Button
        className='align-right'
        data-testid='lab-contents-button'
        disabled={!topologyUP}
        onClick={onContentsClick}
      >
        <Icon name='list' />
        Contents
      </Button>
    </div>
  );
});

export const LabGuideFrame: React.FC<Props> = memo(
  ({ lab, visible, onVisibleChange, onContentChange }) => {
    const [iframeHeight, setIframeHeight] = useState('1100px');
    const dispatch = useDispatch();
    const labState = useSelector((state: RootState) => state.lab.lab as Lab);
    const isContent = useSelector((state: RootState) => state.lab.isContent);
    const [currentPage, setCurrentPage] = useState(
      () => lab.progress?.currentPage ?? 0
    );

    const { useUpdateLab, useDeleteLab } = useLabMutations();

    const labGuideSrcLinks = useMemo(() => getLabTemplateUrls(lab), [lab]);

    const topologyUP = useMemo(
      () =>
        lab.topologies.length > 0 &&
        lab.topologies.every(topology => topology.state === 'up'),
      [lab.topologies]
    );

    const currentUrl = useMemo(() => {
      if (
        isEmpty(labGuideSrcLinks) ||
        isNullOrUndefined(labGuideSrcLinks[currentPage])
      ) {
        return '';
      }
      return `${host}/lab${labGuideSrcLinks[currentPage].url}`;
    }, [labGuideSrcLinks, currentPage]);

    const isLastPage = currentPage === labGuideSrcLinks.length;

    const handleIframeLoad = useCallback(() => {
      const frame = document.getElementById('iframe');
      if (isFrame(frame) && frame.contentWindow?.document?.body) {
        setIframeHeight(`${frame.contentWindow.document.body.clientHeight}px`);
        setIFrameAnchorsTarget(frame.contentWindow.document);
      }
    }, []);

    const handleDelete = useCallback(() => {
      useDeleteLab.mutate(lab);
      dispatch(setSidebarIsContentType(false));
      dispatch(setCurrentLab(undefined));
      window.location.href = interpolateRoute(routes.dashboard.path);
    }, [lab, dispatch, useDeleteLab]);

    const updateProgress = useCallback(
      (step: number) => {
        setCurrentPage(step);
        const data = {
          uuid: lab.uuid,
          progress: { currentPage: step, totalPages: lab.progress.totalPages }
        };
        useUpdateLab.mutate(data);
        dispatch(setSidebarIsContentType(isContent));
        dispatch(setCurrentLab({ ...labState, progress: data.progress }));
        document.getElementById('content')?.scrollTo(0, 0);
      },
      [lab, labState, isContent, dispatch, useUpdateLab]
    );

    const handleLabDetailsClick = useCallback(() => {
      onVisibleChange(true);
      onContentChange(false);
      dispatch(setSidebarIsContentType(false));
      dispatch(setCurrentLab(labState));
    }, [onVisibleChange, onContentChange, dispatch, labState]);

    const handleContentsClick = useCallback(() => {
      onVisibleChange(true);
      onContentChange(true);
      dispatch(setSidebarIsContentType(true));
      dispatch(setCurrentLab(labState));
    }, [onVisibleChange, onContentChange, dispatch, labState]);

    useEffect(() => {
      handleIframeLoad();
    }, [handleIframeLoad]);

    useEffect(() => {
      if (labState?.progress) {
        setCurrentPage(labState.progress.currentPage);
      }
    }, [labState]);

    return (
      <div>
        <LabDetailsOverlay topologies={lab.topologies} visible={!topologyUP} />

        {!isLastPage && (
          <NavigationButtons
            visible={visible}
            topologyUP={topologyUP}
            onLabDetailsClick={handleLabDetailsClick}
            onContentsClick={handleContentsClick}
          />
        )}

        <div className='lab-frame'>
          {!isEmpty(currentUrl) && !isLastPage && (
            <iframe
              id='iframe'
              title='LabGuideFrame'
              onLoad={handleIframeLoad}
              data-testid='lab-guide-frame'
              scrolling='no'
              className='container'
              src={currentUrl}
              referrerPolicy='origin'
              height={iframeHeight}
            />
          )}
        </div>

        <div className='lab-frame'>
          {!isEmpty(currentUrl) && !isLastPage && (
            <LabGuidePageNavigator
              activeSteps={currentPage}
              stepItemsLength={labGuideSrcLinks.length}
              disablePrevButton={currentPage === 0}
              disableNextButton={
                currentPage === labGuideSrcLinks.length || !topologyUP
              }
              setActiveSteps={updateProgress}
            />
          )}
        </div>

        {isLastPage && (
          <LabCompletionActions
            lab={lab}
            onDelete={handleDelete}
            onRestart={updateProgress}
          />
        )}
      </div>
    );
  }
);
