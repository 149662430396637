import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Divider } from 'semantic-ui-react';
import { permissionsUtils } from 'utils';
import { urlStateParser } from 'utils/urlStateParser';

import { ContactsTable, DefaultColumns } from 'components/ContactsTable';
import { RoleDropdown } from 'components/RoleDropdown';
import { SearchForm } from 'components/SearchForm';
import { permissions } from 'constants/permissions';
import { CONTACTS_URL_PARAMS } from 'constants/url-params';
import { useRouter } from 'hooks/useRouter';

import { ContactActionContainer } from './ContactActionContainer';
import { CreateNewUserLink } from './CreateNewUserLink';

import './styles.less';

export const CONTACT_ROLE_QUERY = 'role';

export const PARTNER_ROLE_VALUE = 'partner';
export const CUSTOMERS_ROLE_VALUE = 'customer';
export const ALL_ROLES_VALUE = 'all';
export const SE_ROLE_VALUE = 'se';

export const CONTACT_ROLE_OPTIONS = {
  all: {
    label: 'All',
    value: ALL_ROLES_VALUE,
    permissions: null
  },
  internal: {
    label: 'Internal',
    value: 'internal',
    permissions: permissions.contacts.maySeeInternals
  },
  support: {
    label: 'Support',
    value: 'support',
    permissions: permissions.contacts.maySeeSupport
  },
  customers: {
    label: 'Customers',
    value: CUSTOMERS_ROLE_VALUE,
    permissions: null
  },
  partners: {
    label: 'Partners',
    value: PARTNER_ROLE_VALUE,
    permissions: null
  },
  se: {
    label: 'SE',
    value: SE_ROLE_VALUE,
    permissions: permissions.contacts.mayChangeInternal
  }
};

export const getAvailableOptions = (userRole?: string) => {
  return Object.values(CONTACT_ROLE_OPTIONS).filter(
    ({ permissions: optionPermissions }) =>
      !optionPermissions ||
      permissionsUtils.checkPermissions(optionPermissions, userRole)
  );
};

export const Contacts: React.FC = () => {
  const router = useRouter();
  const getInitialStateFromURL = (location: { search: string }) => {
    return urlStateParser.getStateFromURL(location, CONTACTS_URL_PARAMS);
  };
  const { filterText, roleText, limit, offset } = getInitialStateFromURL(
    router.location
  );

  const [filter, setFilter] = useState<string>(filterText);
  const [roles, setRoles] = useState<string[]>(roleText || []);
  useEffect(() => {
    const newParams = urlStateParser.stateToURLParams(
      { filterText: filter, roleText: roles, limit, offset },
      CONTACTS_URL_PARAMS
    );
    const currentParams = new URLSearchParams(router.location.search);

    if (newParams.toString() !== currentParams.toString()) {
      router.history.push({
        pathname: router.pathname,
        search: newParams.toString()
      });
    }
  }, [
    filter,
    roles,
    limit,
    offset,
    router.location.search,
    router.history,
    router.pathname
  ]);
  const handleRoleToggle = useCallback(
    (value: string) => {
      if (!roles.includes(value)) {
        setRoles([...roles, value]);
      } else {
        setRoles(roles.filter(role => role !== value));
      }
    },
    [roles, setRoles]
  );

  return (
    <div className='cl-contacts'>
      <Fragment>
        <div className='caption'>
          <h2>{'Contacts'}</h2>
          <div>
            <div className='actions'>
              <SearchForm
                filter={filter}
                placeholder='Search contacts. Hit Enter to search'
                updateFilter={setFilter}
                inputDropdownAction={
                  <RoleDropdown roles={roles} onRoleToggle={handleRoleToggle} />
                }
              />
              <div className='flex-1' />
              <ContactActionContainer />
              <CreateNewUserLink />
            </div>
          </div>
        </div>
        <Divider />
        <ContactsTable
          filter={filter}
          roles={roles}
          limit={limit}
          offset={offset}
          displayedColumns={DefaultColumns}
          selectionType={'multi'}
          options={['allowDepartmentUpdate']}
        />
      </Fragment>
    </div>
  );
};
