import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { notifier } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { Template } from 'types';

export const useTemplates = (): UseQueryResult<Template[]> => {
  return useQuery<Template[]>('Templates', () => {
    return axios
      .get(apiPrefix(apiRoutes.templates))
      .then(response => (response.data ? response.data.templates : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};
