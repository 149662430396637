export type ParamType = 'string' | 'number' | 'array';
type URLValue = string | number | string[];

export interface URLParamConfig {
  name: string;
  type: ParamType;
  defaultValue?: any;
  urlParam?: string;
}

interface URLState {
  filterText: string;
  limit: number;
  offset: number;
  roleText?: string[];
  selectedTags?: string[];
}

class URLStateParser {
  private paramParsers: Record<
    ParamType,
    (value: string, config: URLParamConfig) => any
  > = {
    string: value => value,
    number: value => Number(value),
    array: (value, config) => value.split(',').filter(Boolean)
  };

  getStateFromURL(
    location: { search: string },
    configs: URLParamConfig[]
  ): URLState {
    const params = new URLSearchParams(location.search);

    return configs.reduce(
      (state, config) => ({
        ...state,
        [config.name]: this.parseParam(params, config)
      }),
      {}
    ) as URLState;
  }

  private parseParam(params: URLSearchParams, config: URLParamConfig) {
    const paramName = config.urlParam || config.name;
    const rawValue = params.get(paramName);
    return rawValue === null
      ? config.defaultValue
      : this.paramParsers[config.type](rawValue, config);
  }

  stateToURLParams(
    state: Record<string, URLValue>,
    params: URLParamConfig[]
  ): URLSearchParams {
    const urlParams = new URLSearchParams();

    params.forEach(param => {
      const value = state[param.name];
      const urlKey = param.urlParam || param.name;

      if (value !== undefined && value !== null && value !== '') {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            urlParams.set(urlKey, value.join(','));
          }
        } else {
          urlParams.set(urlKey, String(value));
        }
      }
    });

    return urlParams;
  }
}

export const urlStateParser = new URLStateParser();
