import { isEmpty, isEqual } from 'lodash';
import React, { memo, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Label } from 'semantic-ui-react';
import { authUtils, notifier, tagUtils } from 'utils';
import RecentValuesStorage from 'utils/storage';

import { tagsApi } from 'api/tag';
import { MultiSelect } from 'components/MultiSelect';
import { Tag } from 'types/tag';
import { TopologyDetails } from 'types/topology/topology-details';

import './styles.less';

type Props = {
  readonly topology: TopologyDetails;
  readonly refresh: () => void;
};

export const TopologyTags: React.FC<Props> = memo(({ topology, refresh }) => {
  const loggedIn = authUtils.loggedIn();

  const recentTagsStorage = useMemo(
    () => new RecentValuesStorage('RECENT_USED_TAGS'),
    []
  );
  const [associatedTags, setTags] = useState<string[]>([]);

  const getRecentlyUsedTagsFromStorage: string[] = recentTagsStorage.getAll();

  const recentlyUsedOptions: string[] = useMemo(() => {
    return Array.isArray(getRecentlyUsedTagsFromStorage)
      ? getRecentlyUsedTagsFromStorage
      : [];
  }, [getRecentlyUsedTagsFromStorage]);

  const { data: allTags = [], isLoading } = useQuery({
    queryKey: ['Tags'],
    queryFn: async () => {
      const response = await tagsApi.getAll();
      const allTagsResponse = response.tags;
      const topologyTags = topology.tags
        .filter(tag => tagUtils.nonDepartmentTag(tag))
        .map(tag => `${tag.name}:${tag.value}`);
      setTags(topologyTags);
      return allTagsResponse;
    },
    onError: error => {
      notifier.requestFailed(error);
    },
    enabled: loggedIn
  });

  useMemo(() => {
    const topologyTags = topology.tags
      .filter(tag => tagUtils.nonDepartmentTag(tag))
      .map(tag => `${tag.name}:${tag.value}`);
    setTags(topologyTags);
  }, [topology]);

  const existingTags = useMemo(
    () => allTags.filter(tag => tagUtils.nonDepartmentTag(tag)),
    [allTags]
  );
  const suggestions = useMemo(
    () =>
      existingTags.map(tag => ({
        key: tag.id,
        value: `${tag.name}:${tag.value}`,
        text: `${tag.name}:${tag.value}`,
        description: recentlyUsedOptions.includes(`${tag.name}:${tag.value}`)
          ? 'Recently used'
          : ''
      })),
    [existingTags, recentlyUsedOptions]
  );
  const getSelectedTagsWithIds = (): Tag[] | undefined => {
    const selectedTags = associatedTags.map(tag => {
      const [name, value] = tag.split(':');
      return { name, value };
    });
    if (isEqual(selectedTags, topology.tags)) {
      return undefined;
    }
    const departmentTags = topology.tags.filter(
      tag => !tagUtils.nonDepartmentTag(tag)
    );
    return allTags.filter(tag =>
      [...selectedTags, ...departmentTags].some(
        selected => selected.name === tag.name && selected.value === tag.value
      )
    );
  };
  const { mutate: modifyTags } = useMutation({
    mutationFn: async () => {
      const newTags = getSelectedTagsWithIds();
      if (newTags) {
        await tagsApi.updateTopologyTag(topology.uuid, { tags: newTags });
        notifier.success({
          message: `Topology ${
            topology.label ? topology.label : topology.name
          } was updated with tags`
        });
      }
    },
    onSuccess: () => {
      refresh();
    },
    onError: error => {
      notifier.requestFailed(error);
    }
  });

  return (
    <div className='topology-tags'>
      {loggedIn && (
        <MultiSelect
          loading={isLoading}
          placeholderText={
            isEmpty(suggestions) ? 'No tags available' : 'Select Tags'
          }
          availableOptions={suggestions}
          existingOptions={associatedTags}
          onApply={modifyTags}
          onChange={(value: string[]) => {
            setTags(value);
            recentTagsStorage.update(value);
          }}
        />
      )}
      {!loggedIn && associatedTags.map(tag => <Label key={tag}>{tag}</Label>)}
      {!loggedIn && isEmpty(associatedTags) && <Label>No tags added</Label>}
    </div>
  );
});
