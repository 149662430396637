import React, { memo, useRef } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { isTruthy } from 'utils';

/**
 * [CL-3385] Multi-Select Searchable Dropdown Component
 *
 * This component functions similarly to the Jira label selector. It provides a
 * searchable dropdown that supports multiple selections.
 *
 * ## Features:
 * - **Displays Selected Values:** Shows the values provided via props.
 * - **Duplicate Prevention:** Ensure that non-selected options are pre-filtered
 *   to avoid duplicates.
 * - **Apply on Blur:** The dropdown triggers the `onApply` prop (e.g., an API call)
 *   when it loses focus (`onBlur` event).
 * - **Keyboard Navigation:** Fully supports keyboard interactions.
 * - **Clearable:** Users can remove one or more selected values.
 *
 * ## Usage Notes:
 * - The parent component should handle filtering of available options before passing them.
 * - Ensure `onApply` is properly implemented to handle value updates.
 */

type Props = {
  /**
   * A dropdown can show that it is currently loading data.
   */
  readonly loading: boolean;
  /** An array of all values available to select.
   * The structure should follow DropdownItemProps structure.
   */
  readonly availableOptions: DropdownItemProps[];
  /** Current value array.
   * The structure should still follow DropdownItemProps structure.
   * Although Dropdown component value props doesn't recognize DropdownItemProps,
   * so should be kept as any[]
   */
  readonly existingOptions: string[];
  /**
   * Callback function to call an api to apply changes.
   */
  readonly onApply: () => void;
  /**
   * Callback function called when the user attempts to change the value.
   * This includes adding, removing, or all clear
   * Receives new array as an argument.
   */
  readonly onChange: (value: string[]) => void;
  /** Placeholder text. */
  readonly placeholderText: string;
};

export const MultiSelect: React.FC<Props> = memo(
  ({
    loading,
    onApply,
    onChange,
    availableOptions,
    placeholderText,
    existingOptions
  }) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    return (
      <div ref={dropdownRef}>
        <Dropdown
          placeholder={placeholderText}
          fluid
          selection
          clearable
          multiple
          search
          value={existingOptions}
          options={availableOptions}
          loading={loading}
          onBlur={onApply}
          onChange={(event, data) => {
            onChange(data.value as string[]);
            const element = dropdownRef.current
              ?.firstElementChild as HTMLElement;
            if (element && !isTruthy(element.ariaExpanded)) {
              element?.click();
            }
          }}
        />
      </div>
    );
  }
);
