import React, { memo } from 'react';
import { Card, Icon, SemanticICONS } from 'semantic-ui-react';
import { interpolateRoute } from 'utils';

import { routes } from 'constants/routing';

import { RouterLink } from '../RouterLink';

import './styles.less';

type Props = {
  readonly id?: number;
};

interface QuickLinkProps {
  to: string;
  icon: SemanticICONS;
  title: string;
}

const QuickLink: React.FC<QuickLinkProps> = memo(({ to, icon, title }) => {
  return (
    <Card raised link href={to} as={RouterLink} to={to}>
      <Card.Content textAlign='center'>
        <Icon name={icon} size='big' />
      </Card.Content>
      <Card.Description textAlign='center'>{title}</Card.Description>
    </Card>
  );
});

export const QuickLinks: React.FC<Props> = memo(({ id }) => {
  return (
    <Card.Group itemsPerRow={5} className={'card-wrapper'}>
      <QuickLink to={routes.createLabs.path} icon='lab' title='New Lab' />
      <QuickLink
        to={routes.createTopologyForUsers.path}
        icon='sitemap'
        title='New Topology'
      />
      <QuickLink
        to={interpolateRoute(routes.createUserContact.path, {
          id
        })}
        icon='user outline'
        title='New User'
      />
      <QuickLink
        to={routes.chat.path}
        icon='comment outline'
        title='Chat Assistant'
      />
      <QuickLink
        to={routes.apstraSizingCalculator.path}
        icon='calculator'
        title='Sizing Calculator'
      />
    </Card.Group>
  );
});
