import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { useDispatch } from 'react-redux';
import { notifier } from 'utils';

import { apiPrefix } from 'constants/api-routes';
import {
  Lab,
  LabListResponse,
  LabRequest,
  LabTemplate,
  Progress,
  SidebarState,
  SidebarView
} from 'types';

import { setSidebarIsContentType } from 'features/labs';
import qs from 'qs';

export const useLabTemplates = (): UseQueryResult<LabTemplate[]> => {
  return useQuery<LabTemplate[]>('LabTemplates', () => {
    return axios
      .get(apiPrefix('/v1.3/labtemplates'))
      .then(response => (response.data ? response.data.labTemplates : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};

export const useFilterGetAllLabs = (
  params: {
    filter?: string | null | undefined;
    offset?: number;
    limit?: number;
  },
  createdForId: number[] = [],
  createdById: number[] = []
) => {
  const { data, isLoading, refetch } = useGetAllLabs(
    params,
    createdForId,
    createdById
  );
  return {
    responseData: data ? data.labs : [],
    total: data?.total,
    isLoading,
    refetch
  };
};

export const useGetAllLabs = (
  params: {
    filter?: string | null | undefined;
    offset?: number;
    limit?: number;
  },
  createdForId: number[] = [],
  createdById: number[] = []
): UseQueryResult<LabListResponse> =>
  useQuery<LabListResponse>(
    ['Labs', params, createdForId, createdById],
    async () => {
      return await axios
        .get(apiPrefix(`/v1.3/labs`), {
          params: {
            ...params,
            createdForId,
            createdById
          },
          paramsSerializer: param => {
            return qs.stringify(param, { arrayFormat: 'repeat' });
          }
        })
        .then(resp => (resp.data ? resp.data : []))
        .catch(error => {
          notifier.requestFailed(error);
          return [];
        });
    },
    {
      notifyOnChangeProps: 'tracked'
    }
  );

export const useGetLab = (uuid: string): UseQueryResult<Lab> =>
  useQuery<Lab>('Lab', () => {
    return axios
      .get(apiPrefix(`/v1.3/labs/${uuid}`))
      .then(response => response.data)
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
export const useLabMutations = () => {
  const queryClient = useQueryClient();

  const useCreateLab = useMutation(
    (labRequest: LabRequest) => {
      return axios
        .post(apiPrefix('/v1.3/labs/deploy'), labRequest)
        .then(response => {
          notifier.success({
            message: `Lab Created.`
          });
          notifier.success({
            message: `Scheduled creation of Topology.`
          });
          return response.data;
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSettled: () => queryClient.invalidateQueries('Labs') }
  );

  const useDeleteLab = useMutation(
    (lab: Lab) => {
      return axios
        .delete(apiPrefix(`/v1.3/labs/${lab.uuid}`))
        .then(response => {
          notifier.success({
            message: `Lab ${lab.label} deleted.`
          });
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSuccess: () => queryClient.invalidateQueries('Labs') }
  );

  const useUpdateLab = useMutation(
    (data: { uuid: string; progress: Progress }) => {
      return axios
        .put(apiPrefix(`/v1.3/lab/${data.uuid}/progress`), data.progress)
        .then(response => {
          notifier.success(
            {
              message: 'Saved progress of lab'
            },
            { autoClose: 2000 }
          );
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSuccess: () => queryClient.invalidateQueries('Lab') }
  );

  return {
    useCreateLab,
    useDeleteLab,
    useUpdateLab
  };
};

export const useLabSidebarState = (lab: Lab | null) => {
  const [state, setState] = useState<SidebarState>({
    isVisible: false,
    view: SidebarView.NONE
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarIsContentType(state.view === SidebarView.CONTENTS));
  }, [state.view, dispatch]);

  const updateState = useCallback((newState: Partial<SidebarState>) => {
    setState(prev => ({ ...prev, ...newState }));
  }, []);

  return [state, updateState] as const;
};
