import axios from 'axios';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult
} from 'react-query';
import { notifier } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { CreateTopology, Topology, TopologyDetails } from 'types';

import qs from 'qs';

interface TopologiesResponse {
  topologies: Topology[];
  total: number;
}

export const useGetAllTopologies = (params: {
  filter?: string | null | undefined;
  offset?: number;
  limit?: number;
  tag?: string[] | undefined;
  paginated?: boolean;
  owner?: number[] | undefined;
  createdForId?: number[] | undefined;
}): UseQueryResult<TopologiesResponse> => {
  return useQuery<TopologiesResponse>('Topologies', () => {
    return axios
      .get(apiPrefix(apiRoutes.topologiesWithCreator), {
        params,
        paramsSerializer: param => {
          return qs.stringify(param, { arrayFormat: 'repeat' });
        }
      })
      .then(response => (response.data ? response.data : []))
      .catch(error => {
        notifier.requestFailed(error);
        return [];
      });
  });
};

export const useGetTopology = (uuid: string): UseQueryResult<TopologyDetails> =>
  useQuery<TopologyDetails>(
    'Topology',
    () => {
      return axios
        .get(apiPrefix(`${apiRoutes.topologiesWithCreator}/${uuid}`))
        .then(response => response.data)
        .catch(error => {
          notifier.requestFailed(error);
          return [];
        });
    },
    {
      refetchInterval: 10000
    }
  );

export const useTopologyMutations = () => {
  const queryClient = useQueryClient();

  const useCreateTopology = useMutation(
    (topologyRequest: CreateTopology) => {
      return axios
        .post(apiPrefix(apiRoutes.topologiesWithCreator), topologyRequest)
        .then(response => {
          notifier.success({
            message: `Topology scheduled for creation`
          });
          return response.data;
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    { onSettled: () => queryClient.invalidateQueries('Topologies') }
  );

  const useDeleteTopology = useMutation(
    (topology: Topology) => {
      return axios
        .delete(apiPrefix(`${apiRoutes.topologies}/${topology.uuid}`))
        .then(response => {
          notifier.success({
            message: `Topology ${topology.name} scheduled for deletion.`
          });
        })
        .catch(error => {
          notifier.requestFailed(error);
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('Topologies');
        queryClient.invalidateQueries('Topology');
      }
    }
  );

  return {
    useCreateTopology,
    useDeleteTopology
  };
};
