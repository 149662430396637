import { validate } from 'email-validator';

import { isNull, isUndefined } from 'lodash';

export const isNullOrUndefined = (value: string) => {
  return isNull(value) || isUndefined(value);
};

export const validateEmail = (email: string) => {
  return validate(email);
};

/**
 * Removes leading whitespace from each line of the input string.
 *
 * @param s - The input string with possible leading indentation on each line.
 * @returns A new string with leading whitespace removed from each line.
 *
 * @example
 * ```typescript
 * const indentedString = `
 *     This is a templated
 *       multiline string
 *         with indentation
 *     that will be ignored.
 * `;
 *
 * const result = stripIndentation(indentedString);
 * console.log(result);
 * // Output:
 * // "This is a templated
 * // multiline string
 * // with indentation
 * // that will be ignored."
 * ```
 */
export const stripIndentation = (s: string): string => {
  return s
    .split('\n')
    .map(l => l.trimStart())
    .join('\n');
};

/**
 * Removes empty lines from the input string.
 *
 * @param s - The input string with possible empty lines.
 * @returns A new string with empty lines removed.
 *
 * @example
 * ```typescript
 * const multilineString = `
 * This is a multiline
 * string with empty lines.
 *
 * There are two empty lines above.
 *
 * This is the last line.
 * `;
 *
 * const result = stripEmptyLines(multilineString);
 * console.log(result);
 * // Output:
 * // "This is a multiline
 * // string with empty lines.
 * // There are two empty lines above.
 * // This is the last line."
 * ```
 */
export const stripEmptyLines = (s: string): string => {
  return s
    .split('\n')
    .filter(l => l.trim() !== '')
    .join('\n');
};

export const isTruthy = (value: string | undefined | null): boolean => {
  return value?.toLowerCase() === 'true';
};
