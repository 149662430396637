import React, { memo, useContext } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import { SidebarView } from 'types/labs';

import { LabViewContext } from '../Lab';
import { LabGuideFrame } from '../LabGuideFrame';

export const LabDetailView: React.FC = memo(() => {
  const context = useContext(LabViewContext);

  if (!context) {
    throw new Error('LabDetailView must be used within a LabContext.Provider');
  }

  const { lab, isLoading, sidebarState, updateSidebarState } = context;

  if (isLoading) {
    return (
      <Segment>
        <Dimmer active inverted>
          <Loader active>Loading...</Loader>
        </Dimmer>
      </Segment>
    );
  }

  if (!lab) {
    return null;
  }

  return (
    <LabGuideFrame
      lab={lab}
      visible={sidebarState.isVisible}
      onVisibleChange={visible => updateSidebarState({ isVisible: visible })}
      onContentChange={isContent =>
        updateSidebarState({
          view: isContent ? SidebarView.CONTENTS : SidebarView.LAB_DETAILS
        })
      }
    />
  );
});
