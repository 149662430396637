import React from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { getAvailableOptions } from 'components';

interface RoleDropdownProps {
  roles: string[];
  onRoleToggle: (value: string) => void;
}

export const RoleDropdown: React.FC<RoleDropdownProps> = ({
  roles,
  onRoleToggle
}) => {
  return (
    <Dropdown icon='filter' button className='icon'>
      <Dropdown.Menu>
        <Dropdown.Header icon='users' content='Filter by Role' />
        <Dropdown.Menu scrolling>
          {getAvailableOptions().map(option => (
            <Dropdown.Item
              key={option.value}
              text={option.label as string}
              value={option.value}
              active={roles.includes(option.value)}
              onClick={(
                event: React.MouseEvent<HTMLDivElement>,
                data: DropdownItemProps
              ) => {
                onRoleToggle(data.value as string);
              }}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};
