import { isEmpty } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import { notifier, paginationUtils } from 'utils';

import { SelectionType } from 'components/ContactsTable/SelectionType';
import { LabsTable } from 'components/LabsTable';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { useGetAllLabs, useLabMutations } from 'hooks/useLabTemplate';
import { useRouter } from 'hooks/useRouter';
import { Lab } from 'types';

import './styles.less';

type Props = {
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly limit: number;
  readonly filter: string | null;
  readonly offset?: number;
  readonly createdForId?: number[];
  readonly createdById?: number[];
};

export const Labs: React.FC<Props> = memo(
  ({
    displayedColumns,
    selectionType,
    createdForId,
    createdById,
    limit = DEFAULT_PAGE_SIZE,
    filter,
    offset
  }) => {
    const router = useRouter();

    const [activePage, setActivePage] = useState<number>(
      offset ? offset + 1 : 1
    );
    const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);
    const [selectedLab, setSelectedLab] = useState<Lab | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const { useDeleteLab } = useLabMutations();

    const params = useMemo(
      () => ({
        filter: !isEmpty(filter) ? filter : undefined,
        offset: offset !== undefined ? offset : activePage - 1,
        limit
      }),
      [filter, activePage, offset, limit]
    );

    const { data, isLoading, refetch } = useGetAllLabs(
      params,
      createdForId,
      createdById
    );
    const total = data?.total || 0;

    const items = useMemo(() => data?.labs || [], [data]);

    const getTotalPages = useCallback(
      (totalCount: number) => {
        return paginationUtils.getTotalPages({
          pageSize: limit,
          totalCount
        });
      },
      [limit]
    );

    const updateActivePage = useCallback(
      (page: number) => {
        router.updateQueryParam('offset', page - 1);
        setActivePage(page);
      },
      [router]
    );

    const handleDeleteClick = useCallback((lab: Lab) => {
      setSelectedLab(lab);
      setConfirmDeleteModelOpen(true);
    }, []);

    const onDelete = async () => {
      if (!selectedLab) return;

      try {
        setIsDeleting(true);
        await useDeleteLab.mutateAsync(selectedLab);
      } catch (err) {
        notifier.requestFailed(err);
      } finally {
        setIsDeleting(false);
        setConfirmDeleteModelOpen(false);
        setSelectedLab(null);
      }
    };

    useEffect(() => {
      if (data && offset && offset >= getTotalPages(data?.total)) {
        updateActivePage(getTotalPages(data?.total));
      } else if (data && offset && offset < 0) {
        updateActivePage(1);
      }
    }, [offset, getTotalPages, data, updateActivePage]);

    useEffect(() => {
      refetch();
    }, [offset, filter, limit, refetch]);

    return (
      <div className='cl-labs-table'>
        <LabsTable
          labs={items}
          selectionType={selectionType}
          displayedColumns={displayedColumns}
          isLoading={isLoading}
          onDelete={handleDeleteClick}
          onPaginationChange={page => {
            updateActivePage(page);
          }}
          activePage={activePage}
          totalPages={getTotalPages(total)}
        />
        <Confirm
          size='mini'
          content={`Are you sure you want to delete ${
            selectedLab ? selectedLab.label : 'this lab'
          }?`}
          open={confirmDeleteModelOpen}
          onCancel={() => setConfirmDeleteModelOpen(false)}
          onConfirm={onDelete}
          confirmButton='Delete'
          loading={isDeleting}
          disabled={isDeleting}
        />
      </div>
    );
  }
);

Labs.displayName = 'Labs';
