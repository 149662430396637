import React, { memo, useCallback, useEffect, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';

import './styles.less';

type Props = {
  readonly filter: string;
  readonly placeholder: string;
  readonly updateFilter: (filter: string) => void;
  readonly inputDropdownAction?: React.ReactNode;
};

export const SearchForm: React.FC<Props> = memo(
  ({ placeholder, filter, updateFilter, inputDropdownAction }) => {
    const [inputValue, setInputValue] = useState(filter);

    useEffect(() => {
      setInputValue(filter);
    }, [filter]);

    const handleSubmit = useCallback(
      (e: React.FormEvent) => {
        e.preventDefault();
        updateFilter(inputValue);
      },
      [inputValue, updateFilter]
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          updateFilter(inputValue);
        }
      },
      [inputValue, updateFilter]
    );

    return (
      <Form onSubmit={handleSubmit}>
        <Input
          type='search'
          placeholder={placeholder}
          className='search-input'
          value={inputValue}
          autoFocus
          action={inputDropdownAction}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
);
