import axios from 'axios';
import { useQuery } from 'react-query';
import { tagUtils } from 'utils';

import { apiPrefix, apiRoutes } from 'constants/api-routes';
import { Tag } from 'types/tag';

const fetchTags = async (): Promise<{ tags: Tag[] }> => {
  const { data } = await axios.get(apiPrefix(apiRoutes.tags));
  return data;
};

export const useTagManage = (open: boolean) => {
  const { data: tagsData, isLoading } = useQuery({
    queryKey: ['tags'],
    queryFn: fetchTags,
    enabled: open
  });

  const allTags = tagsData?.tags ?? [];
  const existingTags = allTags.filter(tagUtils.nonDepartmentTag);

  return {
    existingTags,
    allTags,
    isLoading
  };
};
