import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { paginationUtils, topologyStates } from 'utils';

import { CustomTableHeaderRow } from 'components/CustomTableHeaderRow';
import { Pagination } from 'components/TablePagination';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { useGetAllContacts } from 'hooks/useContacts';
import { useRouter } from 'hooks/useRouter';
import { useGetAllTopologies } from 'hooks/useTopology';
import { Contact, Topology } from 'types';

import { apiRoleFromUIRole, setSelectedContacts } from 'features/contacts';
import { RootState } from 'Reducers/contactReducer';
import { ChooseDepartmentModal } from './ChooseDepartmentModal';
import { ContactTableRow } from './ContactTableRow';
import { SelectionType } from './SelectionType';

import './styles.less';

export type Option = 'allowDepartmentUpdate';

type Props = {
  readonly filter?: string;
  readonly roles?: string[];
  readonly offset?: number;
  readonly limit?: number;
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly options: Option[];
};

export const ContactsTable: React.FC<Props> = ({
  filter,
  roles,
  offset,
  limit = DEFAULT_PAGE_SIZE,
  displayedColumns,
  selectionType,
  options
}) => {
  const router = useRouter();
  const contactsState = useSelector((state: RootState) => state.contact);
  const selectedContacts = contactsState.selected;
  const dispatch = useDispatch();

  const [confirmModalOpen, setDepartmentModalOpen] = useState(false);
  const [contactForDepartmentModal, setContactForDepartmentModal] = useState<
    Contact[]
  >([]);
  const [topologies, setTopologies] = useState<Topology[]>([]);
  const [activePage, setActivePage] = useState<number>(offset ? offset + 1 : 1);

  const params = useMemo(
    () => ({
      filter: !isEmpty(filter) ? filter : undefined,
      offset: offset ? offset : activePage - 1,
      limit,
      role: apiRoleFromUIRole(roles)
    }),
    [filter, roles, activePage, offset, limit]
  );
  const { data, isLoading, refetch } = useGetAllContacts(params);
  const contacts = data?.contacts || [];
  const total = data?.total || 0;
  const { data: topologyData } = useGetAllTopologies({
    paginated: false
  });

  const getTotalPages = useCallback(
    (totalCount: number) => {
      const totalPages = paginationUtils.getTotalPages({
        pageSize: limit,
        totalCount
      });
      return totalPages;
    },
    [limit]
  );
  const updateActivePage = useCallback(
    (page: number) => {
      router.updateQueryParam('offset', page - 1);
      setActivePage(page);
    },
    [router]
  );

  useEffect(() => {
    if (data && offset && offset >= getTotalPages(data?.total)) {
      updateActivePage(getTotalPages(data?.total));
    } else if (data && offset && offset < 0) {
      updateActivePage(1);
    }
  }, [offset, getTotalPages, data, updateActivePage]);

  useEffect(() => {
    if (!topologyData) {
      return;
    }
    setTopologies(topologyData.topologies || []);
  }, [topologyData, dispatch]);

  useEffect(() => {
    refetch();
  }, [offset, filter, roles, limit, refetch]);

  const allOrAnySelected = () =>
    (selectedContacts.length === contacts.length && contacts.length !== 0) ||
    selectedContacts.length > 0;

  const handleSelectAll = async () => {
    const tempContacts =
      selectedContacts.length === contacts.length || selectedContacts.length > 0
        ? []
        : [...contacts];
    dispatch(setSelectedContacts(tempContacts));
  };

  const activeTopologyNumber = (contact: Contact) =>
    topologies.filter(
      topology =>
        topology.createdFor.id === contact.id &&
        topologyStates.topologyActive(topology)
    ).length;
  const inActiveTopologyNumber = (contact: Contact) =>
    topologies.filter(
      topology =>
        topology.createdFor.id === contact.id &&
        topologyStates.topologyInactive(topology)
    ).length;
  const errorTopologyNumber = (contact: Contact) =>
    topologies.filter(
      topology =>
        topology.createdFor.id === contact.id &&
        topologyStates.topologyError(topology)
    ).length;

  const header = (
    <Table.Header>
      <CustomTableHeaderRow
        selectionType={selectionType}
        indeterminate={allOrAnySelected()}
        onChange={() => handleSelectAll()}
        displayedColumns={displayedColumns}
      />
    </Table.Header>
  );

  const footer = (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={displayedColumns.length + 1}>
          <Pagination
            activePage={activePage}
            totalPages={getTotalPages(total)}
            onPaginationChange={page => {
              updateActivePage(page);
            }}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );

  const tableLoading = (
    <Dimmer.Dimmable as={Table} dimmed>
      {header}
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={displayedColumns.length + 1}>
            <Dimmer.Inner active inverted>
              <Loader active>Loading...</Loader>
            </Dimmer.Inner>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
      {footer}
    </Dimmer.Dimmable>
  );

  const tableLoaded = (
    <Table celled striped>
      {header}
      <Dimmer.Dimmable as={Table.Body} dimmed={isLoading} attached={'bottom'}>
        {contacts.map((contact, idx) => (
          <ContactTableRow
            showLoader={idx === 0 && isLoading}
            key={contact.id}
            contact={contact}
            displayedColumns={displayedColumns}
            selectionType={selectionType}
            setDepartmentModalOpen={setDepartmentModalOpen}
            setContactForDepartmentModal={setContactForDepartmentModal}
            activeTopologies={activeTopologyNumber(contact)}
            inactiveTopologies={inActiveTopologyNumber(contact)}
            erroredTopologies={errorTopologyNumber(contact)}
            allowDepartmentUpdate={options.some(
              o => o === 'allowDepartmentUpdate'
            )}
          />
        ))}
      </Dimmer.Dimmable>
      {footer}
    </Table>
  );

  return (
    <>
      {contacts.length === 0 && isLoading ? tableLoading : tableLoaded}
      {confirmModalOpen && (
        <ChooseDepartmentModal
          open={confirmModalOpen}
          setOpen={setDepartmentModalOpen}
          contacts={contactForDepartmentModal}
        />
      )}
    </>
  );
};
