import React, { Fragment, memo, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Confirm,
  Divider,
  Loader,
  StrictBreadcrumbSectionProps,
  Tab
} from 'semantic-ui-react';
import { authUtils, contactUtils, notifier } from 'utils';

import {
  ContactDetailsModal,
  CreateTopologyLink,
  Topologies
} from 'components';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { routes } from 'constants/routing';
import { useContactMutations, useGetContact } from 'hooks/useContacts';
import { useRouter } from 'hooks/useRouter';
import { MainLayout } from 'layouts';
import { Contact } from 'types';

import { AccountDetailsTab } from './AccountDetailsTab';
import { ContactDetailsCaption } from './Caption';

import './styles.less';

export const ContactDetailsView: React.FC = memo(() => {
  const router = useRouter();
  const contactId: number = Number((router.match.params as { id: string }).id);
  const { deleteContact } = useContactMutations();
  const { data, isLoading } = useGetContact(contactId);
  const contact = useMemo(() => (data ? data : ({} as Contact)), [data]);

  const active: string = (router.match.params as { active: string }).active;
  const [activeTab, setActiveTab] = useState<string>(active);

  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const [confirmDeleteModelOpen, setConfirmDeleteModalOpen] = useState(false);
  const [redirect, setRedirect] = useState<boolean>(false);

  const getPanes = () => [
    {
      menuItem: {
        id: 'details',
        key: 'details',
        icon: 'users',
        content: 'Contact'
      },
      render: () => (
        <Tab.Pane active={activeTab === 'details'}>
          <AccountDetailsTab user={contact} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        id: 'topologies',
        key: 'topologies',
        content: 'Active Topologies'
      },
      render: () => (
        <Tab.Pane active={activeTab === 'topologies'}>
          <Topologies
            filter={contact.email}
            limit={DEFAULT_PAGE_SIZE}
            displayedColumns={[
              'topology name',
              'region',
              'creation time',
              'expiration time',
              'created by',
              'created for',
              'department',
              'tags',
              'status',
              'actions'
            ]}
            selectionType={'none'}
            basicNoDataPlaceholder
          />
        </Tab.Pane>
      )
    }
  ];

  const onDelete = async () => {
    if (!contact) {
      return;
    }
    try {
      await deleteContact.mutate(contact);
      setRedirect(true);
    } catch (err) {
      notifier.requestFailed(err);
    }
    setConfirmDeleteModalOpen(false);
  };

  const user = authUtils.getLoggedInUser();
  const breadcrumbSections: StrictBreadcrumbSectionProps[] = [
    {
      content: routes.contacts.label,
      href: routes.contacts.path
    },
    {
      content: isLoading ? (
        <Loader active inline='centered' size='tiny' />
      ) : contact ? (
        contact.name
      ) : null,
      active: true
    }
  ];
  const mayChangeContact =
    contact &&
    (contact.email === user.email ||
      contactUtils.mayChangeByRole(contact.role));

  const panesMap = new Map(
    getPanes().map((object, i) => {
      return [object.menuItem.id, i];
    })
  );

  if (redirect) {
    return <Redirect to={routes.contacts.path} />;
  }
  return (
    <MainLayout
      className='cl-contact-details'
      breadcrumbSections={breadcrumbSections}
    >
      {contact && !isLoading && (
        <Fragment>
          <ContactDetailsCaption
            contact={contact}
            openDetailsModal={() => setDetailsModalOpen(true)}
            mayChangeContact={mayChangeContact}
          />
          <Divider />
          <div className='content create-topology-btn'>
            <div className='create-topology-btn'>
              <Button negative onClick={() => setConfirmDeleteModalOpen(true)}>
                Delete Contact
              </Button>
              <CreateTopologyLink id={contact.id} text='New Topology' />
            </div>
            <Tab
              activeIndex={panesMap.get(activeTab)}
              panes={getPanes()}
              onTabChange={event => {
                const target = event.target as HTMLButtonElement;
                setActiveTab(target.id as string);
              }}
            />
          </div>
        </Fragment>
      )}
      {!contact && !isLoading && <h2>{'Contact Not Found'}</h2>}
      {isLoading && <Loader active />}
      {contact && mayChangeContact && (
        <ContactDetailsModal
          open={detailsModalOpen}
          onClose={() => {
            setDetailsModalOpen(false);
          }}
          contact={contact}
        />
      )}
      <Confirm
        size='mini'
        header={`Delete ${contact.name} user`}
        content={`This action is irreversible. Are you sure you want to delete user ${contact.name}?`}
        open={confirmDeleteModelOpen}
        onCancel={() => setConfirmDeleteModalOpen(false)}
        onConfirm={() => onDelete()}
        confirmButton='Delete'
      />
    </MainLayout>
  );
});
