import React, { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { authUtils, interpolateRoute } from 'utils';

import { RoleDropdown } from 'components/RoleDropdown';
import { SearchForm } from 'components/SearchForm';
import { routes } from 'constants/routing';

import { UserSelectorTableWizard } from './UserSelectorTableWizard';

import './styles.less';

type Props = {
  readonly stepVisible: boolean;
};

export const UserSelectorWizard: React.FC<Props> = memo(({ stepVisible }) => {
  const [filter, setFilter] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const id = authUtils.getLoggedInUser().id;

  const handleRoleToggle = useCallback(
    (value: string) => {
      if (!roles.includes(value)) {
        setRoles([...roles, value]);
      } else {
        setRoles(roles.filter(role => role !== value));
      }
    },
    [roles, setRoles]
  );

  return (
    <div className='cl-create-topology-user-selector' hidden={!stepVisible}>
      <h2 className='section-number'>{<span>Select a User</span>}</h2>
      <div className='search-section'>
        <SearchForm
          filter={filter}
          placeholder='Search contacts. Hit Enter to search'
          updateFilter={setFilter}
          inputDropdownAction={
            <RoleDropdown roles={roles} onRoleToggle={handleRoleToggle} />
          }
        />
        <div className='align-right create-user'>
          <span>Can't find user?</span>
          <Link to={interpolateRoute(routes.createUserContact.path, { id })}>
            <Button icon='plus circle' content='Create New User' />
          </Link>
        </div>
      </div>
      <UserSelectorTableWizard filter={filter} roles={roles} />
    </div>
  );
});
