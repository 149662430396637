import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContactsTable } from 'components';
import { TopologyFieldNames } from 'types';

import { setTopologyFieldName } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';

import './styles.less';

type Props = {
  readonly filter?: string;
  readonly roles?: string[];
};

export const UserSelectorTableWizard: React.FC<Props> = memo(
  ({ filter, roles }) => {
    const contactsState = useSelector((state: RootState) => state.contact);
    const selectedContacts = contactsState.selected;
    const fieldValueNames: TopologyFieldNames = useSelector(
      (state: RootState) => state.fieldValues
    );
    const dispatch = useDispatch();

    useEffect(() => {
      if (
        selectedContacts &&
        selectedContacts.length > 0 &&
        fieldValueNames.owner !== selectedContacts[0].name
      ) {
        dispatch(
          setTopologyFieldName({
            ...fieldValueNames,
            owner: selectedContacts[0].name
          })
        );
      }
    }, [selectedContacts, fieldValueNames, dispatch]);
    return (
      <ContactsTable
        filter={filter}
        roles={roles}
        displayedColumns={[
          'name',
          'company',
          'partner company',
          'department',
          'role',
          'approval'
        ]}
        selectionType={'single'}
        options={[]}
      />
    );
  }
);
