import React, { Fragment, memo, useState } from 'react';
import { Icon, Message, Segment } from 'semantic-ui-react';
import { notifier } from 'utils';

import { ContactDetailsForm } from 'components';
import { useContactMutations } from 'hooks/useContacts';
import { MainLayout } from 'layouts';
import { Contact } from 'types';

import './styles.less';

export const CreateContactView: React.FC = memo(() => {
  const [contact, setContact] = useState<Contact>({} as Contact);

  const { createContact } = useContactMutations();

  const onSubmit = async () => {
    try {
      await createContact.mutateAsync({
        name: contact.name,
        partnerCompany: contact.partnerCompany,
        company: contact.company,
        email: contact.email,
        password: contact.password,
        role: contact.role,
        department: contact.department
      });
      setContact(null as any);
    } catch (err) {
      notifier.requestFailed(err);
    }
  };

  return (
    <MainLayout className='cl-create-contact'>
      <div className='caption'>
        <h2 className='header'>{'Create User'}</h2>
      </div>
      <Fragment>
        <Message size='large'>
          {
            <span>
              <Icon name='info' /> Register a new account to Juniper Apstra
              CloudLabs™
            </span>
          }
        </Message>
        <Segment className='create-contact-form-container'>
          <ContactDetailsForm
            contact={contact}
            submitBtnText='Create'
            submitBtnIcon='add circle'
            onSubmit={onSubmit}
            onChange={(value: Contact) => setContact(value)}
          />
        </Segment>
      </Fragment>
    </MainLayout>
  );
});
