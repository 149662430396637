import { isEmpty } from 'lodash';
import React, { memo, useState } from 'react';
import { Button, Dropdown, Icon, Message, Modal } from 'semantic-ui-react';
import { notifier, TagOption, tagUtils } from 'utils';
import { reloadData } from 'utils/urls';

import { tagsApi } from 'api/tag';
import { Nbsp } from 'components/Nbsp/Nbsp';
import { useTagManage } from 'hooks/useTagManage';
import { Topology } from 'types';
import { Tag } from 'types/Tags/tags';

import './styles.less';

type Props = {
  readonly open: boolean;
  readonly onClose: (value: boolean) => void;
  readonly topologies: Topology[];
};

type TagActionOption = {
  value: TagAction;
  text: string;
};

enum TagAction {
  AddToExisting = 'addToExisting',
  ReplaceAllWith = 'replaceAllWith',
  ClearField = 'clearField',
  FindAndRemoveThese = 'findAndRemoveThese'
}

const TAG_ACTION_OPTIONS: TagActionOption[] = [
  { value: TagAction.AddToExisting, text: 'Add to Existing' },
  { value: TagAction.ReplaceAllWith, text: 'Replace all with' },
  { value: TagAction.ClearField, text: 'Clear Field' },
  { value: TagAction.FindAndRemoveThese, text: 'Find and remove these' }
];

export const TagSelectionModal: React.FC<Props> = memo(
  ({ open, onClose, topologies }) => {
    const { existingTags, allTags, isLoading } = useTagManage(open);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [tagAction, setTagAction] = useState(TagAction.AddToExisting);

    const getAvailableOptions = (): TagOption[] => {
      if (tagAction === TagAction.ClearField) return [];

      return existingTags
        ? existingTags.map(option => {
            return {
              key: option.id,
              value: `${option.name}:${option.value}`,
              text: `${option.name}:${option.value}`
            };
          })
        : [];
    };

    const getInfoMessage = (): string => {
      const messages = {
        [TagAction.AddToExisting]:
          'This action will add the selected tags to the selected topologies.',
        [TagAction.ClearField]:
          'This selection action will remove all tags associated with the selected topologies.',
        [TagAction.FindAndRemoveThese]:
          'This selection action will find and remove the selected tags associated with the selected topologies.',
        [TagAction.ReplaceAllWith]:
          'This selection action will replace all tags associated with the selected topologies and add the selected tags.'
      };

      return messages[tagAction];
    };

    const handleModifyTags = async () => {
      const updatePromises = topologies.map(async topology => {
        try {
          await tagsApi.updateTopologyTag(topology.uuid, {
            tags: filterTagsByActions(topology)
          });
          notifier.success({
            message: `Topology "${topology.name}" updated with Tags`
          });
        } catch (err) {
          notifier.requestFailed(err);
          return false;
        }
        return true;
      });

      const results = await Promise.all(updatePromises);
      if (results.every(Boolean)) {
        reloadData();
        onClose(false);
      }
    };

    const getIdForSelectedTags = (topology: Topology): Tag[] => {
      const splitSelectedTags =
        selectedTags.length >= 1
          ? selectedTags.map(tag => {
              const selected = tag.split(':');
              return {
                name: selected[0],
                value: selected[1]
              };
            })
          : [];

      const filterTags: Partial<Tag>[] = [];
      splitSelectedTags.forEach(tag => filterTags.push(tag));
      const departmentTag =
        topology &&
        topology.tags.filter(tag => {
          return !tagUtils.nonDepartmentTag(tag);
        });
      filterTags.push(...departmentTag);
      return allTags.filter(tag => {
        return filterTags.find(selected => includesTag(tag, selected));
      });
    };

    const removeTagsFromTopology = (topology: Topology) => {
      const topologyTags = topology.tags.filter(tag =>
        tagUtils.nonDepartmentTag(tag)
      );
      return topologyTags.filter(
        tag =>
          !getIdForSelectedTags(topology).some(selected =>
            includesTag(tag, selected)
          )
      );
    };

    const addTagsToTopology = (topology: Topology) => {
      const topologyTags = removeTagsFromTopology(topology);
      return [...topologyTags, ...getIdForSelectedTags(topology)];
    };

    const filterTagsByActions = (topology: Topology): Tag[] => {
      const actions = {
        [TagAction.AddToExisting]: () => addTagsToTopology(topology),
        [TagAction.ClearField]: () => [],
        [TagAction.FindAndRemoveThese]: () => removeTagsFromTopology(topology),
        [TagAction.ReplaceAllWith]: () => getIdForSelectedTags(topology)
      };

      return actions[tagAction]();
    };

    const includesTag = (original: Partial<Tag>, selected: Partial<Tag>) => {
      return (
        selected.name === original.name && selected.value === original.value
      );
    };

    return (
      <Modal
        open={open}
        closeIcon={true}
        closeOnDimmerClick={false}
        size='tiny'
        onClose={() => {
          onClose(false);
        }}
      >
        <Modal.Header>Manage tags</Modal.Header>
        <Modal.Content>
          <span>Select any options below to manage tags:</span>
          <Dropdown
            fluid
            selection
            value={tagAction}
            options={TAG_ACTION_OPTIONS}
            onChange={(_, data) => setTagAction(data.value as TagAction)}
          />
          <Message
            info
            content={
              <span className='message-wrap'>
                {getInfoMessage()} <Nbsp />
              </span>
            }
          />
          <div className='modal-content'>
            <Modal.Description>
              {tagAction !== TagAction.ClearField && (
                <Dropdown
                  placeholder={
                    isEmpty(getAvailableOptions())
                      ? 'No tags available'
                      : 'Select Tags'
                  }
                  data-testid='tags-selection'
                  fluid
                  selection
                  multiple
                  search
                  loading={isLoading}
                  value={selectedTags}
                  options={getAvailableOptions()}
                  onChange={(_, data) =>
                    setSelectedTags(data.value as string[])
                  }
                />
              )}
            </Modal.Description>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className='flex-1'>
            <Button
              className=''
              color='red'
              inverted
              onClick={() => onClose(false)}
            >
              <Icon name='remove' /> Cancel
            </Button>
            <Button color='green' inverted onClick={() => handleModifyTags()}>
              <Icon name='checkmark' /> Apply
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
);
