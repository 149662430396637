import humanizeDuration from 'humanize-duration';
import { isEmpty } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { TopologyStatus } from 'utils';

import { TopologyDetails } from 'types';

type Props = {
  readonly topologies: TopologyDetails[];
  readonly visible: boolean;
};

const REFRESH_INTERVAL = 60000;

const getEstimatedTimeToDeployMessage = (
  inProgressTopologies: TopologyDetails[]
): string => {
  if (inProgressTopologies.length === 0) return '';

  const topologyTimes = inProgressTopologies.map(topology => {
    const startTime = new Date(topology.creationTime.replace(' ', 'T') + 'Z');
    const currentTime = new Date();
    const elapsedMinutes =
      (currentTime.getTime() - startTime.getTime()) / (1000 * 60);
    const expectedMinutes = topology.templateData?.params
      ?.expectedTimeToDeployInSeconds
      ? topology.templateData.params.expectedTimeToDeployInSeconds / 60
      : 40;

    return {
      remainingMinutes: expectedMinutes - elapsedMinutes,
      topology
    };
  });

  const longestRemaining = Math.max(
    ...topologyTimes.map(t => t.remainingMinutes)
  );

  if (topologyTimes.some(t => t.remainingMinutes < -5)) {
    return 'Your topologies are taking longer than expected to deploy, please be patient.';
  }

  if (topologyTimes.every(t => t.remainingMinutes < 5)) {
    return 'Your topologies are still being deployed, few minutes left.';
  }

  const durationMs = Math.max(0, Math.round(longestRemaining)) * 60 * 1000;
  const formattedDuration = humanizeDuration(durationMs, {
    round: true,
    units: ['h', 'm'],
    largest: 2
  });
  return `Your topologies are currently being deployed, estimated time remaining: ~${formattedDuration}`;
};

const getStatusMessage = (
  topologies: TopologyDetails[],
  updateTrigger: number
): string => {
  if (isEmpty(topologies)) {
    return 'No topologies available, please try creating new lab.';
  }

  const inProgressTopologies = topologies.filter(topology =>
    [
      TopologyStatus.DEPLOY_IN_PROGRESS,
      TopologyStatus.PENDING_DEPLOYMENT
    ].includes(topology.state as TopologyStatus)
  );

  if (inProgressTopologies.length > 0) {
    return getEstimatedTimeToDeployMessage(inProgressTopologies);
  }

  if (topologies.some(topology => topology.state === TopologyStatus.ERROR)) {
    return 'Topology deployment failed, please try creating new lab.';
  }

  if (topologies.some(topology => topology.state === TopologyStatus.DOWN)) {
    return 'Topology either expired or was removed, please try creating new lab.';
  }

  if (
    topologies.some(
      topology => topology.state === TopologyStatus.SHUTDOWN_IN_PROGRESS
    )
  ) {
    return 'Topology is being deleted, please try creating new lab.';
  }

  return '';
};

const StatusMessage: React.FC<{ message: string; loading?: boolean }> = memo(
  ({ message, loading }) => {
    if (loading) {
      return (
        <Loader inline='centered' size='large' indeterminate>
          {message}
        </Loader>
      );
    }
    return <>{message}</>;
  }
);

export const LabDetailsOverlay: React.FC<Props> = memo(
  ({ topologies, visible }) => {
    const [updateTrigger, setUpdateTrigger] = useState(0);

    useEffect(() => {
      if (visible) {
        const timer = setInterval(() => {
          setUpdateTrigger(prev => prev + 1);
        }, REFRESH_INTERVAL);

        return () => clearInterval(timer);
      }
    }, [visible]);

    const topologyInProgress = useMemo(
      () =>
        topologies.some(topology =>
          [
            TopologyStatus.DEPLOY_IN_PROGRESS,
            TopologyStatus.PENDING_DEPLOYMENT
          ].includes(topology.state as TopologyStatus)
        ),
      [topologies]
    );

    const statusMessage = useMemo(
      () => getStatusMessage(topologies, updateTrigger),
      [topologies, updateTrigger]
    );

    if (!visible) return null;

    return (
      <Dimmer
        className='overlay-lab-dimmer'
        active
        data-testid='deploy-lab-overlay'
      >
        <StatusMessage message={statusMessage} loading={topologyInProgress} />
      </Dimmer>
    );
  }
);
