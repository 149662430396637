import React, { Fragment, memo, useMemo } from 'react';
import { Divider, Label, Loader, Message, Segment } from 'semantic-ui-react';
import { authUtils, formatUTCDate, getUTCDate, getUTCFromNow } from 'utils';

import { StateLabel } from 'components';
import { Nbsp } from 'components/Nbsp/Nbsp';
import { useRouter } from 'hooks/useRouter';
import { useGetTopology } from 'hooks/useTopology';
import { MainLayout } from 'layouts';
import { TopologyDetails } from 'types/topology/topology-details';

import { InstructionsGuidedLabs } from './InstructionsGuidedLabs';
import { TopologyCards } from './TopologyCards';
import { TopologyMenu } from './TopologyMenu';
import { TopologyNameHeader } from './TopologyNameHeader';
import { TopologyTags } from './TopologyTags';
import { VMs } from './VMs';

import './styles.less';

type Props = {
  readonly children?: React.ReactNode;
};

const TopologyViewLayout: React.FC<Props> = memo(({ children }) => {
  const loggedIn = authUtils.loggedIn();
  const className = 'cl-topology-view';
  if (loggedIn) {
    return <MainLayout className={className}>{children}</MainLayout>;
  }
  return <div className={className}>{children}</div>;
});

export const TopologyView: React.FC = memo(() => {
  const router = useRouter();
  const uuid: string = (router.match.params as { id: string }).id;

  const { data, refetch, isLoading } = useGetTopology(uuid);
  const topology = useMemo(() => (data ? data : ({} as TopologyDetails)), [
    data
  ]);
  if (isLoading || !data) {
    return <Loader active inline='centered' />;
  }
  const renderExpiration = () => (
    <Fragment>
      {formatUTCDate(topology.expirationTime)}
      <span className='from-now'>
        ({getUTCFromNow(topology.expirationTime)})
      </span>
    </Fragment>
  );
  return (
    <TopologyViewLayout>
      <div>
        <TopologyNameHeader topology={topology} />
        <TopologyMenu topology={topology} refetchData={refetch} />
        <Segment style={{ marginTop: '-15px' }}>
          <div className='caption'>
            <div>
              <div className='topology-info'>
                <span className='title'>Topology state:</span>
                <span>
                  <StateLabel state={topology.state} />
                </span>
              </div>
              {topology.region && (
                <div className='topology-info'>
                  <span className='title'>Region:</span>
                  <span>{topology.region}</span>
                </div>
              )}
              {topology.expirationTime && (
                <div className='topology-info'>
                  <span className='title'>Expiration Time:</span>
                  {getUTCDate()
                    .add(1, 'h')
                    .isAfter(getUTCDate(topology.expirationTime)) ? (
                    <Label basic horizontal size='small' color='red'>
                      {renderExpiration()}
                    </Label>
                  ) : (
                    renderExpiration()
                  )}
                </div>
              )}
              {topology.createdFor && (
                <div className={'topology-info'}>
                  <span className={'title'}>Owner:</span>
                  <span>
                    {topology.createdFor.name} (email:
                    <Nbsp />
                    {topology.createdFor.email})
                  </span>
                </div>
              )}
              <div className='topology-info'>
                <span className='title tag-title'>Tags:</span>
                <TopologyTags topology={topology} refresh={refetch} />
              </div>
            </div>
          </div>
          {topology.statusMessage && (
            <Message warning>
              <Message.Header>Status message</Message.Header>
              <Message.List>
                {topology.statusMessage.split('\n').map((msg, index) => {
                  return <Message.Item key={index}>{msg}</Message.Item>;
                })}
              </Message.List>
            </Message>
          )}
          <TopologyCards topology={topology} refetchData={refetch} />
          <Divider />
          <InstructionsGuidedLabs topologyName={topology.name} />
          <Divider />
          <VMs vms={topology.vms} />
        </Segment>
      </div>
    </TopologyViewLayout>
  );
});
