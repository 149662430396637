import React, { memo, useContext, useMemo } from 'react';
import { Progress } from 'semantic-ui-react';
import { getUTCFromNow } from 'utils';
import { calculateLabProgress } from 'utils/labs';

import { SidebarView } from 'types/labs';
import { LabViewContext } from 'views';

import { LabSidebarContent } from './LabSidebarContent';
import { LabSidebarDetails } from './LabSidebarDetails';

import './styles.less';

type ProgressBarProps = {
  readonly progress: string | number;
};

const ProgressBar: React.FC<ProgressBarProps> = memo(({ progress }) => (
  <div className='progress-bar-container'>
    <div className='progress-bar-text'>Lab Progress</div>
    <div className='progress-bar'>
      <Progress
        className='bar'
        data-testid='progress-bar'
        percent={progress}
        size='small'
        color='green'
        inverted
      />
      <div className='progress-percent'>{progress}%</div>
    </div>
  </div>
));

type LabHeaderProps = {
  readonly label: string;
  readonly expiresAt: string;
};

const LabHeader: React.FC<LabHeaderProps> = memo(({ label, expiresAt }) => (
  <>
    <div className='label-text'>{label}</div>
    <div className='description-text'>
      Expiration Time: {getUTCFromNow(expiresAt)}
    </div>
  </>
));

export const LabSidebar: React.FC = memo(() => {
  const context = useContext(LabViewContext);

  const progress = useMemo(
    () => (context?.lab ? calculateLabProgress(context.lab) : 0),
    [context?.lab]
  );
  if (!context?.lab) {
    return null;
  }

  const { lab, sidebarState } = context;
  const isContentView = sidebarState.view === SidebarView.CONTENTS;

  return (
    <>
      <LabHeader label={lab.label} expiresAt={lab.expiresAt} />
      <ProgressBar progress={progress} />
      {isContentView ? (
        <LabSidebarContent lab={lab} />
      ) : (
        <LabSidebarDetails lab={lab} />
      )}
    </>
  );
});
