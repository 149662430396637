import { PaginatedResponse } from 'interfaces/response/paginated-response';
import { Contact } from 'types/contact';
import { Region } from 'types/region';
import { TopologyDetails } from 'types/topology';

export type LabRequest = {
  label: string;
  labTemplate: string;
  region: string;
  createdForId: number;
};

export type LabId = {
  labUuid: string;
};

export interface LabResponse extends LabId {
  createdFor: Contact;
}

export type LabGuideUrl = {
  name: string;
  url: string;
};

export type Progress = {
  currentPage: number;
  totalPages: number;
};

export type LabTemplate = {
  uuid: string;
  createdAt: string;
  createdBy: Contact;
  labGuideUrls: LabGuideUrl[];
  topologyTemplates: number[];
  label: string;
  shortDescription: string;
  longDescription: string;
  approved: boolean;
  defaultExpirationHours: number;
  supportedMilestones: string[];
  usageTotalCount: number;
};

export type Lab = {
  uuid: string;
  label: string;
  labTemplate: LabTemplate;
  createdBy: Contact;
  createdFor: Contact;
  topologies: TopologyDetails[];
  createdAt: string;
  expiresAt: string;
  region: Region;
  progress: Progress;
};

export enum SidebarView {
  NONE = 'none',
  LAB_DETAILS = 'labDetails',
  CONTENTS = 'contents'
}

export interface SidebarState {
  isVisible: boolean;
  view: SidebarView;
}

export interface LabContext {
  lab: Lab | null;
  isLoading: boolean;
  sidebarState: SidebarState;
  updateSidebarState: (state: Partial<SidebarState>) => void;
}

export interface LabListResponse extends PaginatedResponse {
  labs: Lab[];
}
